<template>
  <div class="stand-out-block">
    <div v-if="tag" class="tag">{{ tag }}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    tag: {type: String, required: false},
  },
};
</script>

<style lang="scss" scoped>
.stand-out-block {
  background-color: $background-light;
  border-radius: 4px;
  padding: 20px;
  position: relative;

  .tag {
    position: absolute;
    top: -32px;
    height: 32px;
    left: 0;
    background-color: $primary-400;
    color: $off-white;
    z-index: 1;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::v-deep {
    hr {
      color: $divider-line-alternative-color;
    }

    .supplemental {
      color: $neutral-500;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    padding: 16px;
  }
}
</style>
